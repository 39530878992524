let resumeData = {
  "name": "Manuel Cánepa",
  "role": "Magento Expert",
  "linkedinId": "https://www.linkedin.com/in/manuelcanepa/",
  "roleDescription": "I like solving problems and accepting challenging projects, they are the ones that motivate me the most, even in my spare time I am looking for something new to develop. ",
  "socialLinks": [{
    "name": "linkedin", "url": "https://www.linkedin.com/in/manuelcanepa/", "className": "fa fa-linkedin"
  }, {
    "name": "github", "url": "http://github.com/manuelcanepa", "className": "fa fa-github"
  }, {
    "name": "twitter", "url": "http://twitter.com/pythonizo", "className": "fa fa-twitter"
  }],
  "aboutme": [
    "Programmer, mainly focused on ecommerce, but keeping an open mind to new challenges.",
    "I was born in 1985, in the city of San Nicolás de los Arroyos, I have been curious about technology since the " +
    "beginning, in the neighborhood I was the one who experimented with video consoles and understood more how to " +
    "operate the devices and systems than kicking a ball.",
    "At the age of ten I started with computer courses, the first thing was «Logo Programmer», followed by «Lotus» " +
    "and «BASIC».",
    "When it was time to enter secondary school, I opted for a school with a computer science orientation and since " +
    "then I have been programming, although I dedicated myself professionally a few years later, the subjects that " +
    "were defining my vocation were systems design, programming, mathematics and physics."
  ],
  "address": "Argentina",
  "website": "https://gento.com.ar",
  "education": [{
    "UniversityName": "Universidad Tecnológica Nacional, Facultad Regional San Nicolás (UTN)",
    "specialization": "SENIOR TECHNICIAN IN PROGRAMMING",
    "MonthOfPassing": "Dec",
    "YearOfPassing": "2019",
  }, {
    "UniversityName": "Escuela de Enseñanza Técnica Nº 2 \"Gral. Manuel N. Savio\" San Nicolás - Buenos Aires",
    "specialization": "COMPUTER TECHNICIAN, PERSONAL AND PROFESSIONAL",
    "MonthOfPassing": "Dec",
    "YearOfPassing": "2004",
  }],
  "work": [{
    CompanyName: "Iurco",
    specialization: "Magento Expert",
    Achievements: 'ERP Integration for Newsan. RabbitMQ Catalog, Orders and Sellers async integration.'
  }, {
    CompanyName: "RubikSolutions",
    specialization: ".Net Developer",
    Achievements: 'Multiples landing pages and ERP'
  }, {
    CompanyName: "SemExpert / Lyracons",
    specialization: "Magento Expert",
    MonthOfLeaving: "Jul",
    YearOfLeaving: "2021",
    Achievements: 'Work on some sites like Grupo Peñaflor, Salentein, BGH, Violetta Cosmeticos, Sparta Chile, Paradineiro, Heyas, La Nación Libros, Ganadera Panamericana, Cocot, Res, Metasport, Vans, Complot, Naima, Saracho.'
  }, {
    CompanyName: "SantexGroup",
    specialization: "Magento Expert",
    MonthOfLeaving: "Jun",
    YearOfLeaving: "2018",
    Achievements: 'Work on TiendaNico.com and Holatuercas.com'
  }, {
    CompanyName: "Estudio12",
    specialization: "Magento Expert",
    MonthOfLeaving: "Jun",
    YearOfLeaving: "2017",
    Achievements: 'Work on rdj4u.com, bsas4u.com, digital-storeweb.com.ar and solodeportes.com.ar'
  }, {
    CompanyName: "inTacto",
    specialization: "Magento Expert",
    MonthOfLeaving: "Jun",
    YearOfLeaving: "2014",
    Achievements: 'Work on sanasana.com.ec site. Stock and price integration with ERP.'
  }, {
    CompanyName: "Itecnis",
    specialization: "Python and OpenERP (Odoo) Developer",
    MonthOfLeaving: "Oct",
    YearOfLeaving: "2011",
    Achievements: 'AFIP Integration to Odoo ERP'
  }, {
    CompanyName: "Baillyweb",
    specialization: "PHP Developer",
    Achievements: 'Create some custom site for Oscar Bailly'
  }, {
    CompanyName: "e-fluxus",
    specialization: "PHP/Python Developer",
    MonthOfLeaving: "Feb",
    YearOfLeaving: "2011",
    Achievements: 'Develop an entire webcrawler using python as engine and php to show the results'
  }],
  "skillsDescription": "My skills",
  "skills": [{
    "skillname": "Magento"
  }, {
    "skillname": "PHP"
  }, {
    "skillname": "MySql"
  }, {
    "skillname": "Wordpress"
  }, {
    "skillname": "C-Sharp"
  }, {
    "skillname": "Git"
  }, {
    "skillname": "Docker"
  }, {
    "skillname": "RabbitMQ"
  }, {
    "skillname": "MongoDB"
  }],
  "portfolio": [{
    name: "Newsan",
    description: "Magento 2 ERP Integration using RabbitMQ",
    imgurl: "images/portfolio/newsan.png",
    href: 'https://www.tiendanewsan.com.ar'
  }, {
    name: "Violetta Cosméticos", description: "Magento 2 customizations", imgurl: "images/portfolio/violetta.png",
    href: 'https://www.violettacosmeticos.com'
  }, {
    name: "Farmacias Paradineiro",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/paradineiro.png",
    href: 'https://www.paradineirofarmacias.com.ar'
  }, {
    name: "Res Carnicería",
    description: "Magento 2 customizations and custom command system integrated with magento 2 apis",
    imgurl: "images/portfolio/res.png",
    href: 'https://res.com.ar'
  }, {
    name: "NoAflojes",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/noaf.jpg",
    href: 'https://noaflojes.com.ar'
  }, {
    name: "Complot",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/complot.png",
    href: 'https://complot.com.ar'
  }, {
    name: "Nico Tiendas",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/nico.png",
    href: 'https://www.nicotiendas.com'
  }, {
    name: "Farmacias Sana Sana",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/sanasana.png",
    href: 'https://www.sanasana.com.ec'
  }, {
    name: "Solo Deportes",
    description: "Magento 2 customizations",
    imgurl: "images/portfolio/solo-deportes.png",
    href: 'https://www.solodeportes.com.ar'
  }, {
    name: "Fisio React",
    description: "Custom schedule and reservation system",
    imgurl: "images/portfolio/fisio-react.png",
    href: 'https://fisioreact.com'
  }, {
    name: "Pam i Pipa",
    description: "Custom administration site",
    imgurl: "images/portfolio/pamipipa.jpg",
    href: 'https://pamipipa.cat'
  }, {
    name: "Casa Maini", description: "Magento 2 customizations", imgurl: "images/portfolio/casamaini.jpg"
  }, {
    name: "Laminados Industriales",
    description: "Complete lamination processing system",
    imgurl: "images/portfolio/laminados-industriales.jpg",
    href: 'https://www.laminadosindustrialessa.com'
  }, {
    name: "EVHSA",
    description: "Public transport schedules and routes",
    imgurl: "images/portfolio/evhsa.png",
    href: 'https://www.e-evhsa.com.ar'
  }, {
    name: "Parque Industrial Comirsa",
    description: "Landing page",
    imgurl: "images/portfolio/comirsa.jpg",
    href: 'http://www.parquecomirsa.com.ar'
  }, {
    name: "Mi Viaje Seguro",
    description: "Personalized travel insurance contracting system",
    imgurl: "images/portfolio/mvs.jpg",
    href: 'https://miviajeseguro.com.ar'
  }],
  "testimonials": [{
    // "description": "This is a sample testimonial", "name": "Some technical guy"
  }]
}

export default resumeData